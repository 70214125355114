<template>
    <div>
        <vx-card>
        <vs-row style="margin-bottom: 3%">
            <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="2">
                 <treeselect
                    v-model="initSelectedCourses"
                    :multiple="true"
                    :options="treeDataCourses"
                />
            </vs-col>
            <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="3" v-if="this.select_app_status === 'Application Submitted'">
                <v-select
                label="text"
                class="w-full"
                placeholder="Application Status"
                :options="applicationStatusOption"
                v-model="select_app_status"
                ></v-select>
            </vs-col>
            <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="6" v-if="this.select_app_status !== 'Application Submitted'">
                <v-select
                label="text"
                class="w-full"
                placeholder="Application Status"
                :options="applicationStatusOption"
                v-model="select_app_status"
                ></v-select>
            </vs-col>
            <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="2.5" v-if="this.select_app_status === 'Application Submitted'">
                <!-- <v-select
                searchable
                clearable
                label="text"
                class="w-full"
                placeholder="Application Stage"
                :options="applicationStageOption"
                v-model="select_app_stage"
                ></v-select> -->
                <treeselect
                    placeholder="Levels"
                    v-model="initSelectedStage"
                    :multiple="false"
                    :options="treeDataStage"
                />
            </vs-col>
            <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="2.5" v-if="this.select_app_status === 'Application Submitted'">
                <!-- <v-select
                multiple
                class="w-full"
                placeholder="Application Sub Stage"
                :options="applicationSubStageOption"
                v-model="select_app_sub_stage"
                style="z-index:none"
                ></v-select> -->
                <treeselect
                    v-model="initSelectedSubStage"
                    :multiple="true"
                    :options="treeDataSubStage"
                />
            </vs-col>
            <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="0.5">
                <vs-button
                    color="dark"
                    type="gradient"
                    @click="analyzePagination(1)"
                >Get</vs-button>
            </vs-col>
            <vs-col vs-type="flex" vs-justify="flex-end" vs-align="center" vs-w="1.5">
          <vs-dropdown class="gap" vs-custom-content vs-trigger-click :key="dropdownKey">
                    <vs-button radius color="dark" type="gradient" icon="search"></vs-button>
                    <vs-dropdown-menu class="loginx">
                    <vs-row vs-w="12" style="margin-bottom: 3%">
                        <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="9">
                        <vs-input
                            type="text"
                            label-placeholder="Name"
                            v-model="person_name"
                        />
                        </vs-col>
                        <vs-col
                        vs-type="flex"
                        vs-justify="flex-start"
                        vs-align="flex-end"
                        vs-w="3"
                        >
                        <vs-button
                            color="dark"
                            type="gradient"
                            icon="search"
                            @click="analyzePagination('person_name')"
                        ></vs-button>
                        </vs-col>
                    </vs-row>
                    <vs-row vs-w="12" style="margin-bottom: 3%">
                        <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="9">
                        <vs-input
                            type="text"
                            label-placeholder="Can-Id"
                            v-model="can_id"
                        />
                        </vs-col>
                        <vs-col
                        vs-type="flex"
                        vs-justify="flex-start"
                        vs-align="flex-end"
                        vs-w="3"
                        >
                        <vs-button
                            color="dark"
                            type="gradient"
                            icon="search"
                            @click="analyzePagination('canId')"
                        ></vs-button>
                        </vs-col>
                    </vs-row>
                    
                    <vs-row vs-w="12" style="margin-bottom: 3%">
                        <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="9">
                        <vs-input type="text" label-placeholder="Email" v-model="email" />
                        </vs-col>
                        <vs-col
                        vs-type="flex"
                        vs-justify="flex-start"
                        vs-align="flex-end"
                        vs-w="3"
                        >
                        <vs-button
                            color="dark"
                            type="gradient"
                            icon="search"
                            @click="analyzePagination('email')"
                        ></vs-button>
                        </vs-col>
                    </vs-row>
                    </vs-dropdown-menu>
                </vs-dropdown>
                <vs-button
                    radius
                    class="gap"
                    color="dark"
                    type="gradient"
                    icon="refresh"
                    @click="refreshdata"
                ></vs-button>
            </vs-col>
        </vs-row>
        <vs-row style="margin-top: 3%" v-if="this.DataShowing === true">
                <vs-col vs-w="12" v-if="CourseTableData.length !==0">
                    <vs-card>
                        <vs-table :data="CourseTableData">
                            <template slot="thead">
                                <vs-th> Can-ID </vs-th>
                                <vs-th> Name </vs-th>
                                <vs-th> ExEd Level </vs-th>
                                <vs-th>
                                    Course
                                </vs-th>
                                
                                <vs-th> Application Stage  </vs-th>
                                <vs-th> Application Sub Stage  </vs-th>
                                <vs-th> Application Sent Date </vs-th>
                                <vs-th> Engagement Details  </vs-th>
                                
                                <vs-th> ExEd Last Call </vs-th>
                                <vs-th>ExEd Next Call</vs-th>
                                <vs-th>Info</vs-th>
                                
                            </template>

                            <template slot-scope="{ data }">
                                <vs-tr
                                    :key="indextr"
                                    v-for="(tr, indextr) in data"
                                >
                                    <vs-td :data="data[indextr].identity">
                                        {{ data[indextr].identity }}
                                    </vs-td>

                                    <vs-td :data="data[indextr].person_name">
                                        {{ data[indextr].person_name }}
                                    </vs-td>
                                    <vs-td :data="data[indextr].id">
                                        {{ data[indextr].iiml_level }}
                                    </vs-td>
                                    <vs-td :data="data[indextr].id">
                                        {{ data[indextr].miles_course }}
                                    </vs-td>
                                    <vs-td :data="data[indextr].id">
                                        {{ data[indextr].application_stage }}
                                    </vs-td>
                                    <vs-td :data="data[indextr].id">
                                        {{ data[indextr].application_sub_stage }}
                                    </vs-td>
                                    <vs-td :data="data[indextr].id">
                                        {{ data[indextr].application_sent_date }}
                                    </vs-td>
                                    <vs-td :data="data[indextr].id">
                                        {{ data[indextr].engagement_details }}
                                    </vs-td>
                                    
                                    <vs-td :data="data[indextr].id">
                                        {{
                                            convertTimestampToDate(
                                                data[indextr].iiml_last_call
                                            )
                                        }}
                                    </vs-td>
                                    <vs-td
                                        :data="data[indextr].enrollment_date"
                                    >
                                        {{
                                            convertTimestampToDate(
                                                data[indextr].iiml_next_call
                                            )
                                        }}
                                    </vs-td>
                                    <vs-td>
                                        <vs-row>
                                            <div
                                                @click="
                                                    openPopupEmit(data[indextr].id)
                                                "
                                            >
                                                <vs-icon
                                                    icon="info_outline"
                                                    size="small"
                                                    color="dark"
                                                ></vs-icon>
                                            </div>
                                        </vs-row>
                                    </vs-td>
                                </vs-tr>
                            </template>
                        </vs-table>
                        <vs-row style="margin-top: 3%">
                            <vs-col
                                vs-type="flex"
                                vs-justify="flex-end"
                                vs-align="flex-end"
                                vs-w="12"
                            >
                                <vs-chip
                                    color="primary"
                                    style="margin-right: 2%"
                                >
                                    <b>{{ countsleads }}</b>
                                </vs-chip>
                                <vs-pagination
                                    :total="tablelinks"
                                    v-model="currentTablePage"
                                ></vs-pagination>
                            </vs-col>
                        </vs-row>
                    </vs-card>
                </vs-col>
            </vs-row>
        </vx-card>
    </div>
</template>
<script>
import vSelect from "vue-select";
import axios from "axios";
import constants from "../../constants.json";
import Treeselect from "@riophae/vue-treeselect";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";

export default {
    data() {
        return {
            dropdownKey:0,
            DataShowing: false,
            treeDataCourses: [
                {
                    id: "All",
                    label: "All",
                    children: [
                    
                        {
                        id: "IIML-FA",
                        label: "IIML-FA",
                        },
                        {
                        id: "IIML-FT",
                        label: "IIML-FT",
                        },
                        {
                        id: "IIML-SF",
                        label: "IIML-SF",
                        },
                        {
                        id: "IIML-DA",
                        label: "IIML-DA",
                        },
                        {
                        id: "IIML-HR",
                        label: "IIML-HR",
                        },
                        {
                        id: "IITR-BF",
                        label: "IITR-BF",
                        },
                        {
                        id: "IITR-DB",
                        label: "IITR-DB",
                        },
                        {
                        id: "IITM-AA",
                        label: "IITM-AA",
                        },
                        {
                        id: "IIMK-CX",
                        label: "IIMK-CX",
                        },
                        { id: "IITM-FS", label: "IITM-FS" },
                        { id: "IITR-CC", label: "IITR-CC" },
                        { id: "IIMK-FT", label: "IIMK-FT" },
                        { id: "IIML-AB", label: "IIML-AB" },
                        { id: "IIML-SH", label: "IIML-SH" },
                        { id: "IITJ-DE", label: "IITJ-DE" },
                        { id: "XLRI-HR", label: "XLRI-HR" },
                        { id: "XLRI-SH", label: "XLRI-SH" },
                        { id: "IIMI-BA", label: "IIMI-BA" },
                        { id: "IIMI-AA", label: "IIMI-AA" },
                        { id: "XLRI-DM", label: "XLRI-DM" },
                        {
                        id: "IIML-PM",
                        label: "IIML-PM",
                        },
                        // {
                        //   id: "MAYBE",
                        //   label: "MAYBE"
                        // }
                    ],
                },
            ],
            treeDataStage: [
                {
                    id: 'All',
                    label: 'All',
                    children: [],
                },
            ],
            treeDataSubStage: [
                {
                    id: "All",
                    label: "All",
                    children: [],
                },
            ],
            initSelectedCourses: [],
            initSelectedStage: "",
            initSelectedSubStage: [],
            applicationStageOption: [],
            select_app_stage: "",
            applicationSubStageOption: [],
            select_app_sub_stage: "",
            applicationData: [],
            can_id: "",
            mobile: "",
            email: "",
            person_name: "",
            applicationStatusOption: [
                "Application Sent",
                "Application Submitted",
                "Application Sent But Not Submitted"
            ],
            select_app_status: "",
            SelectedCourse: "",
            SelectedSubStage: "",
            SelectedStatus: "",
            CourseTableData: [],
            tablelinks: 0,
            currentTablePage: 1,
            countsleads: "",
            rawcities: [],
            rawBde: []
        };
    },
    watch: {
        currentTablePage(val) {
            // console.log("current page", val);
            this.analyzePagination(val);
        },
        initSelectedStage(value) {
            console.log("value", value)
            this.rawBde.forEach(element => {
                // value.forEach(stage => {
                    if(element.application_stage === value) {
                        this.getSubStageData(value)
                        // console.log("value", stage)
                    } else if(value === "All") {
                        this.getSubStageData("All")
                        // console.log("value", stage)
                    }
                // });
                
            });
        },
        select_app_status(value) {
            if(value === "Application Sent") {
                //clear applicatiion stage data
                this.SelectedStatus = "application_sent";
                this.initSelectedStage = "";
                this.initSelectedSubStage = [];
            }
            if(value === "Application Submitted") {
                this.SelectedStatus = "application_submitted";
                this.searchData();
            }
            if(value === "Application Sent But Not Submitted") {
                this.SelectedStatus = "application_sent_but_not_submitted";
                this.initSelectedStage = "";
                this.initSelectedSubStage = [];
            }
            
            
        },
        initSelectedCM(value) {
            if(value !== "") {
                this.SelectedCourse = value.join();
            } else {
                this.SelectedCourse = "";
            }
            
            
        },
        select_app_sub_stage(value) {
            if(value !== "") {
                this.SelectedSubStage = value.join();
            } else {
                this.SelectedSubStage = "";
            }
        },
        select_app_stage(value) {
            this.applicationSubStageOption = [];
            this.select_app_sub_stage = "";
            this.applicationData.forEach(element => {
                if(element.application_stage === value) {
                    console.log("application", element.application_sub_stages)
                    this.applicationSubStageOption = element.application_sub_stages;
                }
            });
            
        },
    },
    components: {
        "v-select": vSelect,
        Treeselect
    },
    mounted() {
        this.getSearchData();
    },
    methods: {
        analyzePagination(val) {
            this.dropdownKey = Math.random()
            if (this.currentTablePage === 1) {
                this.searchAPI(1);
            } else {
                this.searchAPI(val);
            }
        },
        openPopupEmit(id) {
            this.openBigPopup(id);
        },
        refreshdata() {
            this.select_app_stage = "";
            this.select_app_sub_stage = "";
            this.can_id = "";
            this.mobile = "";
            this.email = "";
            this.select_app_status = "";
            this.initSelectedCM = [];
            this.DataShowing = false;
        },
        getSearchData() {
            this.$vs.loading();
            this.rawBde = [];
            this.initSelectedCM = [];
            this.treeDataCM = [];
            this.applicationData = [];
            this.applicationStageOption = [];
            let url = `${constants.SERVER_API}getWileyCoursesApplicationStages`;
            axios
                .get(url, {
                headers: { Authorization: `Bearer ${localStorage.userAccessToken}` },
                })
                .then((response) => {
                console.log(response)
                this.rawBde = response.data.stages;
                // response.data.courses.forEach(element => {
                //     let obj = {
                //         id: element,
                //         label: element
                //     }
                //     this.treeDataCM.push(obj);
                // });
                this.$vs.loading.close();
                })
                .catch((error) => {
                this.handleError(error);
                });
        },
        searchData() {
                
                this.treeDataStage[0].children = [];
                this.rawcities = [];
                var unique = this.rawBde
                    .map((name) => {
                    return {
                        count: 1,
                        name: name.application_stage,
                    };
                    })
                    .reduce((a, b) => {
                    a[b.name] = (a[b.name] || 0) + b.count;
                    return a;
                    }, {});
                for (var key in unique) {
                    if (unique.hasOwnProperty(key)) {
                    this.rawcities.push(key);
                    console.log("dummy", key)
                        var obj = {
                            id: key,
                            label: key,
                        };
                        this.treeDataStage[0].children.push(obj);
                    }
                }
                // this.rawBde.forEach(element => {
                //     this.treeDataStage.forEach(bde => {
                //         bde.children.forEach(bdechid => {
                //             if(bdechid.id === element.application_stage) {
                //                 // console.log("bdechid", bdechid)
                //                 // element.application_sub_stages.forEach(chid => {
                //                     var obj = {
                //                         id: element.application_sub_stage,
                //                         label: element.application_sub_stage
                //                     }
                //                     bdechid.children.push(obj)
                //                 // })
                //             }
                //         })
                //     });
                // });
                
                this.treeDataStage[0].children = this.sortNestedArrayAlphabetically(
                    this.treeDataStage[0].children
                );
                // if (this.rawBde.indexOf(this.treeDataStage[0].children.label) === -1) {
                //     this.initSelectedStage.push(this.treeDataStage[0].children.label);
                // }
                console.log("bde", this.rawBde.indexOf(this.treeDataStage[0].children.label))
        },
        getSubStageData(stage) {
            console.log("stage", stage)
            this.treeDataSubStage[0].children = [];
            this.rawBde.forEach(element => {
                if(element.application_stage === stage) {
                    element.application_sub_stages.forEach(chid => {
                        var obj = {
                            id: chid,
                            label: chid
                        }
                        this.treeDataSubStage[0].children.push(obj);
                    });
                } else if(stage === 'All') {
                    this.treeDataSubStage[0].children = [];
                    var unique = this.rawBde
                        .map((name) => {
                        return {
                            count: 1,
                            name: name.application_sub_stages,
                        };
                        })
                        .reduce((a, b) => {
                        a[b.name] = (a[b.name] || 0) + b.count;
                        return a;
                        }, {});
                    for (var key in unique) {
                        if (unique.hasOwnProperty(key)) {
                        this.rawcities.push(key);
                        console.log("dummy", key)
                            var obj = {
                                id: key,
                                label: key,
                            };
                            this.treeDataSubStage[0].children.push(obj);
                        }
                    }
                }
            });
            this.treeDataSubStage[0].children = this.sortNestedArrayAlphabetically(
                this.treeDataSubStage[0].children
            );
            console.log("bde", this.treeDataSubStage[0].children)
        },
        searchAPI(page) {
            let courses = [];
            let Appstage = [];
            let AppSubstage = [];
            this.$vs.loading();
            // this.initSelectedStage.forEach((sort) => {
               if (this.initSelectedStage === "All") {
                this.treeDataStage[0].children.forEach((child) => {
                    Appstage.push(child.label);
                });
                } else {
                    Appstage.push(this.initSelectedStage);
                }
            // });
            this.initSelectedSubStage.forEach((sort) => {
                if (sort === "All") {
                this.treeDataSubStage[0].children.forEach((child) => {
                    AppSubstage.push(child.label);
                });
                } else {
                    AppSubstage.push(sort);
                }
            });
            this.initSelectedCourses.forEach((sort) => {
                if (sort === "All") {
                    this.treeDataCourses[0].children.forEach((child) => {
                        courses.push(child.label);
                    });
                } else {
                    courses.push(sort);
                }
            });
            let obj = {
                page: page,
                courses: courses.join(),
                application_stage: Appstage.join(),
                application_sub_stage: AppSubstage.join(),
                identity: this.can_id,
                email: this.email,
                person_name: this.person_name,
                status: this.SelectedStatus
            }
            let url = `${constants.SERVER_API}getApplicationSentDetails`;
            axios
                .get(url, {
                    params: obj,
                    headers: { Authorization: `Bearer ${localStorage.userAccessToken}` },
                })
                .then((response) => {
                console.log(response)
                this.CourseTableData = response.data.apps.data;
                this.currentTablePage =
                    response.data.apps.current_page;
                this.tablelinks = response.data.apps.last_page;
                this.countsleads = response.data.apps.total;
                this.DataShowing = true;
                this.$vs.loading.close();
                })
                .catch((error) => {
                this.handleError(error);
                });
        }
    }
}
</script>